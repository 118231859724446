<template>
    <div class="content-item">
        <div class="home-title">
            <h2>{{ english }}</h2>
            <h1>{{ chinese }}</h1>
        </div>
        <div class="box">
            <slot />
        </div>
    </div>
</template>

<script>

    export default {
        name: 'content-item',
        props: {
            english: {
                type: String,
                default: ''
            },
            chinese: {
                type: String,
                default: ''
            }
        }
    }
</script>

<style lang="less" scoped>
    @import '~@/assets/less/common.less';

    .content-item {
        .home-title {
            padding-bottom: 120px;
            text-align: center;
            h2 {
                margin-bottom: 10px;
                color: #999;
                font-size: 16px;
                letter-spacing: 2px;
            }
            h1 {
                display: inline;
                position: relative;
                padding-bottom: 40px;
                color: @theme-color;
                font-size: 32px;
                font-weight: bold;
                &:after {
                    position: absolute;
                    left: 50%;
                    transform: translateX(-50%);
                    bottom: 0;
                    width: 50%;
                    height: 1px;
                    background-color: @theme-color;
                    content: '';
                }
            }
        }
    }

    // 移动端
    @media screen and (max-width: 1023px) {
        .content-item {
            .home-title {
                padding-bottom: 0.4267rem;
                h2 {
                    margin-bottom: 0.10667rem;
                    font-size: 0.2667rem;
                }
                h1 {
                    font-size: 0.5333rem;
                    &:after {
                        display: none;
                    }
                }
            }
        }
    }
</style>