<template>
    <fhiry-page class="contact" :banners="banners">
        <fhiry-title
            class="contact-wrapper"
            :chinese="introduction.chinese"
            :english="introduction.english"
        >
            <div class="content-wrapper">
                <div class="intro">
                    <div class="img-wrapper">
                        <img :src="introduction.introImg" alt="">
                    </div>
                    <p class="intro-text">
                        {{ introduction.intro }}
                    </p>
                </div>
                <div class="info">
                    <div class="company">
                        <h1>{{ introduction.info.company }}</h1>
                        <div class="company-info">
                            <span>地址：{{ introduction.info.address }}</span>
                            <span>电话：{{ introduction.info.phone }}</span>
                            <span>邮编：{{ introduction.info.emailNo }}</span>
                            <span>邮箱：{{ introduction.info.email }}</span>
                        </div>
                    </div>
                    <div class="map"></div>
                </div>
            </div>
        </fhiry-title>
    </fhiry-page>
</template>

<script>
    // 联系我们
    // 组件
    import FhiryPage from '@/components/FhiryPage';
    import FhiryTitle from '@/components/FhiryTitle';

    import { getContact } from '@/api/request';

    export default {
        name: 'contact',
        created () {
            this.getContactAjax();
        },
        data () {
            return {
                banners: [],
                introduction: {
                    chinese: '',
                    english: '',
                    info: '',
                    intro: '',
                    introImg: ''
                }
            }
        },
        methods: {
            getContactAjax () {
                getContact().then(res => {
                    console.log(res);
                    this.banners = res.banners
                    this.introduction = res.introduction
                })
            }
        },
        components: {
            FhiryPage,
            FhiryTitle
        }
    }
</script>

<style lang="less" scoped>
    @import '~@/assets/less/common.less';

    .contact {
        .contact-wrapper {
            padding: 80px 0;
            .content-wrapper {
                margin: 0 auto;
                width: @limit-width;
                .intro {
                    position: relative;
                    margin-bottom: 80px;
                    width: 100%;
                    height: 413px;
                    .img-wrapper, .intro-text {
                        width: 53%;
                    }
                    .intro-text {
                        position: absolute;
                        bottom: 0;
                        right: 0;
                        padding: 50px;
                        height: calc(~'100% - 50px');
                        background-color: #FFF;
                        box-shadow: 0 0 10px #999;
                        text-indent: 28px;
                        font-size: 14px;
                        line-height: 1.94;
                    }
                }
                .info {
                    display: flex;
                    height: 172px;
                    .company {
                        flex: 7;
                        h1 {
                            position: relative;
                            padding-bottom: 15px;
                            margin-bottom: 15px;
                            font-size: 24px;
                            &:after {
                                display: block;
                                position: absolute;
                                bottom: 0;
                                left: 0;
                                width: 48px;
                                height: 1px;
                                background-color: #999;
                                content: '';
                            }
                        }
                        .company-info {
                            display: flex;
                            flex-direction: column;
                            font-size: 12px;
                            span {
                                margin-bottom: 15px;
                                color: #777;
                                &:last-child {
                                    margin-bottom: 0;
                                }
                            }
                        }
                    }
                    .map {
                        flex: 3;
                        height: 100%;
                    }
                }
            }
        }
    }

    @media screen and (max-width: 1023px) {
        .contact {
            .contact-wrapper {
                padding: 0.5333rem 0;
                .content-wrapper {
                    padding: 0 0.2666rem;
                    width: 100%;
                    .intro {
                        position: static;
                        margin-bottom: 0.5333rem;
                        height: auto;
                        .img-wrapper, .intro-text {
                            width: 100%;
                            img {
                                width: 100%;
                            }
                        }
                        .intro-text {
                            position: static;
                            margin-top: 0.26666rem;
                            padding: 1.3333rem;
                            height: auto;
                        }
                    }
                    .info {
                        flex-direction: column;
                        height: auto;
                        .company {
                            h1 {
                                font-size: 0.48rem;
                            }
                            .company-info {
                                span {
                                    margin-bottom: 0.266666rem;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
</style>